<template>
    <div class="avatar">
        {{ initials }}
    </div>
</template>

<script setup>
const props = defineProps({
    firstName: {
        type: String,
        default: null
    },
    lastName: {
        type: String,
        default: null
    }
});

const initials = generateInitials(props.firstName, props.lastName);

function generateInitials(firstName = '', lastName = '') {
    const firstLetter = firstName ? firstName.charAt(0) : '';
    const lastLetter = lastName ? lastName.charAt(0) : '';

    return `${firstLetter}${lastLetter}`;
}
</script>

<style lang="less" src="./BaseAvatar.less" />
